// import React, { useState, useEffect } from 'react';
// import axios from 'axios'; // You can use Axios for making API requests
// import Header from '../Header/Header';
// import "./Asana.scss";

// const AsanaTaskList = () => {
//   const [tasks, setTasks] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [bensCamps, setBensCamps] = useState([]);
//   let currUser = localStorage.getItem("firstName").split('')[0];
//   let taskIds = []
//   let totalTime = []


//   useEffect(() => {
//     // Define your Asana API endpoint URL and access token here
//     const apiUrl = 'https://app.asana.com/api/1.0/projects/1206149873258943/tasks'; // Replace with the correct API endpoint
//     const accessToken = '1/1202728739608631:90c545d99e76bcb0477fe9cd6e24da8d'; // Replace with your Asana access token

//     // Make an API request to fetch Asana tasks
//     axios.get(apiUrl, {
//       headers: {
//         'Authorization': `Bearer ${accessToken}`,
//       },
//     })
//     .then(response => {
//       setTasks(response.data.data); // Assuming Asana API returns task data in the 'data' field
//       setLoading(false);
//     })
//     .catch(error => {
//       console.error('Error fetching Asana tasks:', error);
//       setLoading(false);
//     });

//     // Make an API call to populate bensCamps
//     const customFieldApiUrl = 'https://app.asana.com/api/1.0/projects/1206149873258943/tasks?opt_fields=custom_fields.display_value';
//     const customFieldOptions = {
//       method: 'GET',
//       headers: {
//         accept: 'application/json',
//         authorization: `Bearer ${accessToken}`,
//       },
//     };

//     fetch(customFieldApiUrl, customFieldOptions)
//       .then(response => response.json())
//       .then(response => {
//         console.log(response)
//         const bensCamps = [];
//         response.data.forEach(camp => {
//           taskIds.push(camp.gid); 
//           const custFields = camp.custom_fields;
//           custFields.forEach(field => {
//             if (field.display_value === `${currUser}` && field.gid === "1203392235812081") {
//               bensCamps.push(camp.gid);
//             }
//           });

//         taskIds.forEach(task => {
//           fetch(`https://app.asana.com/api/1.0/tasks/${task}/time_tracking_entries`,customFieldOptions)
//           .then(response => response.json())
//           .then(response => {
//             if(response.data.length > 0){
//               response.data.forEach(entry => {
//                 if(entry.duration_minutes){
//                   totalTime.push(entry.duration_minutes)
//                 }
//               })
//             }
//           })
//         })

//         });
//         setBensCamps(bensCamps);
//         setTimeout(console.log(totalTime), 3000);
//       })
//       .catch(err => {
//         console.error('Error populating bensCamps:', err);
//       });
//   }, []); // Empty dependency array ensures this effect runs once on component mount

//   // Filter tasks to include only those with GIDs in bensCamps
//   const filteredTasks = tasks.filter(task => {
//     return bensCamps.includes(task.gid);
//   });

//   return (
//     <>
//       <Header />
//       <div>
//         <h2>Asana Task List</h2>
//         {loading ? (
//           <p>Loading...</p>
//         ) : (
//           <ul>
//             {filteredTasks.map(task => (
//               <li key={task.gid}>{task.name}</li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </>
//   );
// };

// export default AsanaTaskList;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import "./Asana.scss";

const AsanaTaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bensCamps, setBensCamps] = useState([]);
  let currUser = localStorage.getItem("firstName").split('')[0];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://app.asana.com/api/1.0/projects/1206149873258943/tasks';
        const accessToken = '1/1202728739608631:90c545d99e76bcb0477fe9cd6e24da8d';

        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        setTasks(response.data.data);
        setLoading(false);

        const customFieldApiUrl = 'https://app.asana.com/api/1.0/projects/1206149873258943/tasks?opt_fields=custom_fields.display_value';
        const customFieldOptions = {
          method: 'GET',
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };

        const customFieldResponse = await fetch(customFieldApiUrl, customFieldOptions);
        const customFieldData = await customFieldResponse.json();

        const bensCamps = [];
        customFieldData.data.forEach(camp => {
          const custFields = camp.custom_fields;
          custFields.forEach(field => {
            if (field.display_value === `${currUser}` && field.gid === "1203392235812081") {
              bensCamps.push(camp.gid);
            }
          });
        });

        setBensCamps(bensCamps);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currUser]);

  // const fetchTimeEntries = async () => {
  //   const taskIds = bensCamps.map(campGid => campGid);

  //   for (const task of taskIds) {
  //     try {
  //       const timeTrackingResponse = await fetch(`https://app.asana.com/api/1.0/tasks/${task}/time_tracking_entries`);
  //       const timeTrackingData = await timeTrackingResponse.json();

  //       if (timeTrackingData.data.length > 0) {
  //         const totalTime = timeTrackingData.data.reduce((sum, entry) => sum + (entry.duration_minutes || 0), 0);
  //         console.log(`Total time for task ${task}: ${totalTime} minutes`);
  //       }
  //     } catch (error) {
  //       console.error(`Error fetching time entries for task ${task}:`, error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (bensCamps.length > 0) {
  //     fetchTimeEntries();
  //   }
  // }, [bensCamps]);

  const filteredTasks = tasks.filter(task => bensCamps.includes(task.gid));

  return (
    <>
      <Header />
      <div>
        <h2>Asana Task List</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {filteredTasks.map(task => (
              <li key={task.gid}>{task.name}</li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default AsanaTaskList;
