import React from 'react';

import { DateDisplayWrapper,DateBox,Day, Month, DateDetails, FullDate, Description } from './DateDisplay.jsx'

const DateDisplay = ({ day, month, date, description }) => {
    return (
      <DateDisplayWrapper>
        <DateBox>
          <Day>{day}</Day>
          <Month>{month}</Month>
        </DateBox>
        <DateDetails>
          <FullDate>{date}</FullDate>
          <Description>{description}</Description>
        </DateDetails>
      </DateDisplayWrapper>
    );
  };
  
  export default DateDisplay;
  