import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  const [chartData, setChartData] = useState({
    labels: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  useEffect(() => {
    const apiUrl = "https://europe-west2-calendarapi-402309.cloudfunctions.net/calendarapi";

    function parseJSONData(data) {
      return JSON.parse(data.replace(/\\/g, "")); // Remove escaped backslashes and parse
    }

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);

        let developerData = {};
        let consultantData = {};

        data.forEach((user) => {
          try {
            developerData = parseJSONData(user.developer_data);
            consultantData = parseJSONData(user.consultant_data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }

          const summedData = {};

          for (const month in developerData) {
            if (!summedData[month]) {
              summedData[month] = 0;
            }

            summedData[month] += developerData[month] || 0;
            summedData[month] += consultantData[month] || 0;
          }

          // Convert summedData to arrays for chart
          const numericValues = Object.values(summedData);
          const backgroundColors = numericValues.map((value) => {
            if (value < 20) return "#0B6E4F";
            if (value < 30) return "#FA9F60";
            return "#A63446";
          });

          setChartData({
            labels: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
            datasets: [
              {
                data: numericValues,
                backgroundColor: backgroundColors,
              },
            ],
          });
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw} Days`;
          },
        },
      },
      datalabels: {
        display: false, // Hide data labels
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div style={{ backgroundColor: "white", padding: "20px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
