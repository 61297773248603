import { useEffect, useState } from "react";

const useFetchTests = () => {
  const [tests, setTests] = useState([]);

  useEffect(() => {
    // Retrieve and normalize the local storage name
    const firstName = localStorage.getItem("firstName")?.toLowerCase() || "";

    fetch("https://europe-west2-calendarapi-402309.cloudfunctions.net/calendarapi")
      .then((response) => response.json())
      .then((data) => {
        // Normalize and filter the data
        const filteredData = data
          .filter((test) => {
            // Normalize the name from the API
            const normalizedName = test.name.toLowerCase();

            // Check if the normalized local storage name is a substring of the normalized API name
            return normalizedName.includes(firstName);
          })
          .filter((test) => test.name !== "faith") // Additional filtering if needed
          .sort((a, b) => a.name.localeCompare(b.name));

        console.log("Filtered data:", filteredData); // Log filtered data for debugging
        setTests(filteredData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return tests;
};

export default useFetchTests;
