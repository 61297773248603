import styled from 'styled-components';

export const DateDisplayWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 80px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  margin-right: 20px;
  background-color: #fff;
`;

export const Day = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const Month = styled.div`
  font-size: 12px;
  color: #999;
`;

export const DateDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FullDate = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333;
`;

export const Description = styled.div`
  font-size: 12px;
  color: #555;
`;