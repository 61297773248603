import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import { Link } from 'react-router-dom';

const Menu = ({ open, ...props }) => {
  
  const isHidden = open ? true : false;
  const classShown = open ? 'showing' : 'hidden'

  return (
    <StyledMenu className={classShown} open={open} aria-hidden={!isHidden} {...props}>
      <Link className="header__nav__mobile" to="/functions">
            Functions
          </Link>
          <Link className="header__nav__mobile" to="/walkthroughdevs">
            Walkthroughs Dev
          </Link>
          <Link className="header__nav__mobile" to="/walkthroughcros">
            Walkthroughs CRO
          </Link>
          <Link className="header__nav__mobile" to="/tracking">
            Tracking
          </Link>
          <Link className="header__nav__mobile" to="/quirks">
            Quirks
          </Link>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;