import { db } from "../../firebase.config";
import { useState, useEffect } from "react";
import {
  collection, // Get reference to collection from firebase
  onSnapshot,
  doc,
  addDoc,
  deleteDoc 
} from "firebase/firestore";
import './Walkthroughsdev.scss';
import Header from '../Header/Header';

function Walkdevs(){
    const [vids, setVid] = useState ([]);
    const[form, setForm] = useState({
      name: "",
      vid: "",
      desc: ""
    })
  
    const [popupActive, setPopupActive] = useState(false);
  
    const vidsCollectionRef = collection(db, "walkthroughdev");

    document.addEventListener("click", function (event) {
      let targetClass = event.target.classList;
      if (
        targetClass.contains("modalBackdrop") &&
        !targetClass.contains("hidden")
      ) {
        event.preventDefault();
        let btn = document.querySelector(".view.less");
        console.log(btn);
        btn.click();
      }
    });
  
    useEffect(() => {
      onSnapshot(vidsCollectionRef, snapshot => {
        setVid(snapshot.docs.map(doc => {
          return{
            id: doc.id,
            viewing: false,
            ...doc.data()
          }
        }))
      })
    },[]) // empty array so only runs once on page load
  
  //Show more show less functionality
    const handleView = id => {
      const vidsClone = [...vids];
  
      vidsClone.forEach(vid => {
        if(vid.id === id){
          vid.viewing = !vid.viewing;
        } else {
          vid.viewing = false;
        }
      })
  
      setVid(vidsClone)
    }
  
     //Function to add functions straight to firebase and update app
    const handleSubmit = e => {
      e.preventDefault();
  
      if(!form.name || !form.vid || !form.desc){
        alert("Please fill out all the fields");
        return
      }
  
      addDoc(vidsCollectionRef, form)
  
      setForm({
        name: "",
        vid: "",
        desc: ""
      });
  
      setPopupActive(false);
    }
  
    const removeFunc = id => {
      let result = window.confirm("Are you sure you want to delete?")
      if(result){
        deleteDoc(doc(db, "walkthroughdev", id)) // delete functionality, deletes function from firebase and updates app in realtime
      }
    }

    function elementReady(selector) {
        return new Promise((resolve, reject) => {
            let el = document.querySelector(selector);
            if (el) {
              resolve(el);
              return
            }
            new MutationObserver((mutationRecords, observer) => {
              Array.from(document.querySelectorAll(selector)).forEach((element) => {
              resolve(element);
              observer.disconnect();
              });
            })
            .observe(document.documentElement, {
              childList: true,
              subtree: true
            });
        });
        }
        
        elementReady('.functions').then(() => {
          modalFunc();
          checkForModal();
        })
  
        function modalFunc(){
          let funcsContainer = document.querySelector('.functions')
          let observer = new MutationObserver(mutationRecords => {
            console.log(mutationRecords); // console.log(the changes)
            let viewButtons = document.querySelectorAll('.view');
              viewButtons.forEach(button => {
                    if(button.innerHTML === "View less"){
                      button.parentNode.parentNode.classList.add('viewing')
                    } else {
                      button.parentNode.parentNode.classList.remove('viewing')
                    }
              })
          });
          
          // observe everything except attributes
          observer.observe(funcsContainer, {
            childList: true, // observe direct children
            subtree: true, // and lower descendants too
          });
        }
  
        function checkForModal(){
          let funcsContainer = document.querySelector('.functions')
          let modal = document.querySelector('.modalBackdrop');
          let observer = new MutationObserver(mutationRecords => {
              if(document.querySelector('.viewing')){
                modal.classList.remove('hidden');
              }else {
                modal.classList.add('hidden');
              }
          });
          
          // observe everything except attributes
          observer.observe(funcsContainer, {
            childList: true, // observe direct children
            subtree: true, // and lower descendants too
          });
        }
  
    return (
        <>
      <Header/>
      <div className="App">
      <div className="hidden modalBackdrop"></div>
       <h1>Dev Walkthroughs</h1>
  
       <button className = "slide_left" onClick={() => setPopupActive(!popupActive)}>Add Walkthrough</button>
  
       <div className = "functions">
          {vids.map((vid, i) => (
            //Id to retrieve from firebase object
            <div className = "function" key = {vid.id}>
  
              {/* get value from name in object */}
                <h3 className = "function__name">{ vid.name }</h3>
              
              { vid.viewing && <div className="function__info">
                {/* seperate div for function itself and description, for styling purposes */}
                <div className = "function__container">
                  <h4>Walkthrough</h4>
                  <div dangerouslySetInnerHTML={{ __html: vid.vid }}></div>
                </div>
  
                <div className="function__description-cont">
                  <h4>Description</h4>
                  <p className="function__description" dangerouslySetInnerHTML={{ __html: vid.desc }}></p>
                </div>
              </div>}
  
              <div className="buttons">
                <button className = "view less" onClick={() => handleView(vid.id)}>View { vid.viewing ? 'less' : 'more' }</button>
                <button className="remove" onClick={() => removeFunc(vid.id)}>Remove</button>
              </div>
            </div>
          ))}
       </div>

       {popupActive && <div className = "popup">
            <div className = "popup-inner">
              <h2>Add a Walkthrough</h2>
  
              <form onSubmit={handleSubmit}>
  
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" 
                  value = {form.name} 
                  onChange={e => setForm({...form, name: e.target.value})}/>
                </div>
  
                <div className="form-group">
                  <label>Walkthrough</label>
                  <textarea type="text" 
                  value = {form.func} 
                  onChange={e => setForm({...form, vid: e.target.value})}/>
                </div>
  
                <div className="form-group">
                  <label>Description</label>
                  <textarea type="text" 
                  value = {form.desc} 
                  onChange={e => setForm({...form, desc: e.target.value})}/>
                </div>
  
                <div className="buttons">
                  <button typeof="submit">Submit</button>
                  <button type="button" className="remove" onClick={() => setPopupActive(false)}>
                    Close
                  </button>
                </div>
  
              </form>
  
            </div>
       </div>
       } 
      </div>
      </>
    );
}

export default Walkdevs;

