import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import CircularProgress from "./circular.js";
import {
  CalendarWrapper,
  FirstSection,
  SecondSection,
  ThirdSection,
  FirstTopWrapper,
  FirstBotWrapper,
  ThirdTopSection,
  ThirdMidSection,
  ThirdBotSection,
  MonthTitle,
  ChartContainer,
  HolidaysBooked,
  Graph,
} from "./table.styled.jsx";
import BarChart from "./barChart.js";
import DateDisplay from "../DateDisplay/DateDisplay.js";
import useFetchTests from "./data.jsx";
import ComparisonGraph from "./chart.jsx";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

function TestTable() {
  const [tests, setTests] = useState([]);
  const [daysLeft, setDaysLeft] = useState(0);
  const [monthlyData, setMonthlyData] = useState({});
  const [developerData, setDeveloperData] = useState({});
  const [consultantData, setConsultantData] = useState({});
  const [nextEvent, setNextEvent] = useState({ startDate: "", endDate: "" });
  const testsData = useFetchTests();

  useEffect(() => {
    fetch("https://europe-west2-calendarapi-402309.cloudfunctions.net/calendarapi")
      .then((response) => response.json())
      .then((data) => {
        // Filter and sort the tests data
        const filteredData = data.filter((test) => test.name !== "faith");
        filteredData.sort((a, b) => a.name.localeCompare(b.name));
        setTests(filteredData);
        const personName = localStorage.getItem("firstName")?.toLowerCase() || "";
        const personData = data.find((entry) => entry.name.toLowerCase() === personName);

        // Parse and store monthly data
        const parsedMonthlyData = JSON.parse(data[0].monthly_days_available || "{}");
        const parsedDeveloperData = JSON.parse(data[0].developer_data || "{}");
        const parsedConsultantData = JSON.parse(data[0].consultant_data || "{}");
        const parsedEventDates = JSON.parse(personData.event_dates || "[]");
        //console.log(parsedEventDates)

        setMonthlyData(parsedMonthlyData);
        setDeveloperData(parsedDeveloperData);
        setConsultantData(parsedConsultantData);

        // Function to parse date in DD-MM-YYYY format
        const parseDate = (dateString) => {
          const [day, month, year] = dateString.split("-");
          return new Date(`${year}-${month}-${day}`);
        };

        // Determine the next event
        const today = new Date();
        console.log("Today:", today.toLocaleDateString("en-GB"));

        const upcomingEvents = parsedEventDates
          .map((dateRange) => {
            console.log("Date Range:", dateRange);

            const [start, end] = dateRange.split(" / ");
            console.log("Start:", start, "End:", end);

            // Check if date parsing is successful
            const startDate = parseDate(start);
            const endDate = parseDate(end);
            console.log("Parsed Start Date:", startDate.toLocaleDateString("en-GB"), "Parsed End Date:", endDate.toLocaleDateString("en-GB"));

            return { start: startDate, end: endDate };
          })
          .filter((event) => {
            const isUpcoming = event.start > today;
            console.log("Event:", event, "Is Upcoming:", isUpcoming);
            return isUpcoming;
          })
          .sort((a, b) => a.start - b.start);

        console.log("Upcoming Events:", upcomingEvents);

        if (upcomingEvents.length > 0) {
          setNextEvent({
            startDate: upcomingEvents[0].start.toLocaleDateString("en-GB"),
            endDate: upcomingEvents[0].end.toLocaleDateString("en-GB"),
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const firstName = localStorage.getItem("firstName")?.toLowerCase() || "";
    const totalDays = 30;

    const daysUsed = testsData.filter((test) => test.name.toLowerCase().includes(firstName)).reduce((sum, test) => sum + test.total_days, 0);

    const calculatedDaysLeft = totalDays - daysUsed;
    setDaysLeft(calculatedDaysLeft);
  }, [testsData]);

  // Get the current month and format it
  const getCurrentMonth = () => {
    const now = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[now.getMonth()];
  };
  const monthAbbreviations = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const formatMonth = (monthNumber) => {
    const index = parseInt(monthNumber, 10) - 1;
    return monthAbbreviations[index] || monthNumber;
  };
  const getOrdinalSuffix = (day) => {
    const dayNumber = parseInt(day, 10);
    if (dayNumber % 10 === 1 && dayNumber % 100 !== 11) {
      return "st";
    } else if (dayNumber % 10 === 2 && dayNumber % 100 !== 12) {
      return "nd";
    } else if (dayNumber % 10 === 3 && dayNumber % 100 !== 13) {
      return "rd";
    } else {
      return "th";
    }
  };

  const currentMonth = getCurrentMonth();
  const currentMonthKey = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, "0")}`;
  const [hoveredGraph, setHoveredGraph] = useState(null);

  const devsOffThisMonth = developerData[currentMonthKey] || 0;
  const consultantsOffThisMonth = consultantData[currentMonthKey] || 0;
  const totalDaysAvailable = monthlyData[currentMonthKey] || 0;

  // Calculate total days and hours for developers and consultants
  const devsDaysAvailable = totalDaysAvailable * 4;
  const consultantsDaysAvailable = totalDaysAvailable * 5;

  // Convert days to hours
  const convertDaysToHours = (days) => days * 8;
  const handleMouseEnter = (graph) => setHoveredGraph(graph);
  const handleMouseLeave = () => setHoveredGraph(null);

  return (
    <>
      <Header />
      <CalendarWrapper>
        <FirstSection>
          <FirstTopWrapper>
            <p>Days left until end of December</p>
            <span class="linebreak"></span>
            <CircularProgress days={daysLeft} totalDays={30} />
          </FirstTopWrapper>
          <FirstBotWrapper>
            <p className="titleWrapper">Hi {localStorage.getItem("firstName")},</p>
            <p className="request">Your next AL is:</p>
            <span class="linebreak"></span>
            <div>
              {nextEvent.startDate && nextEvent.endDate && (
                <>
                  <DateDisplay day={nextEvent.startDate.split("/")[0]} month={formatMonth(nextEvent.startDate.split("/")[1])} date={`${nextEvent.startDate.split("/")[0]}${getOrdinalSuffix(nextEvent.startDate.split("/")[0])} ${formatMonth(nextEvent.startDate.split("/")[1])}`} />
                  <DateDisplay day={nextEvent.endDate.split("/")[0]} month={formatMonth(nextEvent.endDate.split("/")[1])} date={`${nextEvent.endDate.split("/")[0]}${getOrdinalSuffix(nextEvent.endDate.split("/")[0])} ${formatMonth(nextEvent.endDate.split("/")[1])}`} />
                </>
              )}
            </div>
          </FirstBotWrapper>
        </FirstSection>
        <SecondSection>
          <MonthTitle>
            <p>{currentMonth}</p>
            <span class="linebreak"></span>
          </MonthTitle>

          <HolidaysBooked>
            <div className="squareDesc">
              <div class="squareDev">
                <span></span>Dev
              </div>
              <div class="squareCons">
                <span></span>Consultant
              </div>
            </div>

            <div className="outsideWrapperHols">
              <div className="holsPlanned-Container">
                <h3>Holidays Planned</h3>
                <div className="holsPlanned-Wrapper">
                  <Graph onMouseEnter={() => handleMouseEnter("devs")} onMouseLeave={handleMouseLeave}>
                    <CircularProgressbar
                      value="100"
                      text={hoveredGraph === "devs" ? `${convertDaysToHours(devsOffThisMonth)} hours` : `${devsOffThisMonth} days`}
                      background="true"
                      styles={buildStyles({
                        pathColor: "#FBAD60",
                        textColor: "black",
                        backgroundColor: "rgba(251, 173, 96, 0.5)",
                      })}
                    />
                  </Graph>

                  <Graph onMouseEnter={() => handleMouseEnter("consultants")} onMouseLeave={handleMouseLeave}>
                    <CircularProgressbar
                      value="100"
                      text={hoveredGraph === "consultants" ? `${convertDaysToHours(consultantsOffThisMonth)} hours` : `${consultantsOffThisMonth} days`}
                      background="true"
                      styles={buildStyles({
                        pathColor: "#69B7C9",
                        textColor: "black",
                        backgroundColor: "rgba(105, 183, 201, 0.5)",
                      })}
                    />
                  </Graph>
                </div>
              </div>
              <div className="holsPlanned-Container">
                <h3>Working days</h3>
                <div className="holsPlanned-Wrapper">
                  <Graph onMouseEnter={() => handleMouseEnter("devsWorking")} onMouseLeave={handleMouseLeave}>
                    <CircularProgressbar
                      value="100"
                      text={hoveredGraph === "devsWorking" ? `${convertDaysToHours(devsDaysAvailable)}   hours` : `${devsDaysAvailable} days`}
                      background="true"
                      styles={buildStyles({
                        pathColor: "#FBAD60",
                        textColor: "black",
                        backgroundColor: "rgba(251, 173, 96, 0.5)",
                      })}
                    />
                  </Graph>
                  <Graph onMouseEnter={() => handleMouseEnter("consultantsWorking")} onMouseLeave={handleMouseLeave}>
                    <CircularProgressbar
                      value="100"
                      text={hoveredGraph === "consultantsWorking" ? `${convertDaysToHours(consultantsDaysAvailable)} hours` : `${consultantsDaysAvailable} days`}
                      background="true"
                      styles={buildStyles({
                        pathColor: "#69B7C9",
                        textColor: "black",
                        backgroundColor: "rgba(105, 183, 201, 0.5)",
                      })}
                    />
                  </Graph>
                </div>
              </div>
              <div className="holsPlanned-Container">
                <h3>Available Resource</h3>
                <div className="holsPlanned-Wrapper">
                  <Graph onMouseEnter={() => handleMouseEnter("devsAvailable")} onMouseLeave={handleMouseLeave}>
                    <CircularProgressbar
                      value="100"
                      text={hoveredGraph === "devsAvailable" ? `${convertDaysToHours(devsDaysAvailable - devsOffThisMonth)} hours` : `${devsDaysAvailable - devsOffThisMonth} days`}
                      background="true"
                      styles={buildStyles({
                        pathColor: "#FBAD60",
                        textColor: "black",
                        backgroundColor: "rgba(251, 173, 96, 0.5)",
                      })}
                    />
                  </Graph>

                  <Graph onMouseEnter={() => handleMouseEnter("consultantsAvailable")} onMouseLeave={handleMouseLeave}>
                    <CircularProgressbar
                      value="100"
                      text={hoveredGraph === "consultantsAvailable" ? `${convertDaysToHours(consultantsDaysAvailable - consultantsOffThisMonth)} hours` : `${consultantsDaysAvailable - consultantsOffThisMonth} days`}
                      background="true"
                      styles={buildStyles({
                        pathColor: "#69B7C9",
                        textColor: "black",
                        backgroundColor: "rgba(105, 183, 201, 0.5)",
                      })}
                    />
                  </Graph>
                </div>
              </div>
            </div>
          </HolidaysBooked>

          <ChartContainer>
            <ComparisonGraph devsDaysAvailable={devsDaysAvailable} devsWorkingDays={devsDaysAvailable - devsOffThisMonth} consultantsDaysAvailable={consultantsDaysAvailable} consultantsWorkingDays={consultantsDaysAvailable - consultantsOffThisMonth} />
          </ChartContainer>
        </SecondSection>
        <ThirdSection>
          <ThirdTopSection>
            {testsData.map((test) => (
              <React.Fragment key={test.name}>
                <div className="mainSectionWrapper">
                  <p className="endOfYear">Year end December</p>
                  <p className="contractualAllowance">
                    Contractual allowance <span>30</span>
                  </p>
                  <span className="linebreak"></span>

                  <p className="total">
                    Total <span>30</span>
                  </p>
                  <p className="annualLeave">
                    Annual Leave <span>{test.total_days}</span>
                  </p>
                </div>
                <p className="daysLeftOfHols">
                  Remaining<span>{30 - test.total_days}</span>
                </p>
              </React.Fragment>
            ))}
          </ThirdTopSection>
          <ThirdMidSection>
            <p className="timeOffTracking">Your time off</p>
            <span className="linebreak"></span>
            <div>
              <p className="public_Holidays">
                Public Holidays<span>8</span>
              </p>
              <p className="sicknessDays">
                {" "}
                Sickness<span>X</span>
              </p>
            </div>
          </ThirdMidSection>
          <ThirdBotSection>
            <p className="annualTrendMessage">Annual Holiday Trends</p>
            <span class="linebreak"></span>
            <BarChart />
          </ThirdBotSection>
        </ThirdSection>
      </CalendarWrapper>
    </>
  );
}

export default TestTable;
