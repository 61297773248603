import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../abstracts/conversio_logo.svg";
import Hamburger from "../../Components/Hamburger/Hamburger";
import Menu from "../Menu/Menu";
import "./Header.scss";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const menuId = "main-menu";

  useEffect(() => {
    const updateWindowSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check the window width on component mount
    updateWindowSize();

    // Listen for window resize events
    window.addEventListener("resize", updateWindowSize);

    // Remove the resize event listener to avoid memory leaks
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <nav className={`header ${isMobile ? "mobile" : ""}`}>
      {isMobile ? (
        <ThemeProvider theme={theme}>
          <Hamburger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </ThemeProvider>
      ) : (
        <div className="header__buttons-container">
          <Link to="/homepage">
            <img
              className="header__logo"
              src="https://conversio.com/wp-content/uploads/2024/06/Conversio_colour_dk@2000x.png.webp"
              alt="Conversio logo"
            />
          </Link>
          <Link className="header__nav" to="/functions">
            Functions
          </Link>
          <div className="dropdown">
            <button className="header__nav">
              Walkthroughs
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </button>
            <div className="dropdown-content">
              <Link className="dropdown__item" to="/walkthroughdevs">
                Dev
              </Link>
              <Link className="dropdown__item" to="/walkthroughcros">
                CRO
              </Link>
            </div>
          </div>
          <Link className="header__nav" to="/tracking">
            Tracking
          </Link>
          <Link className="header__nav" to="/quirks">
            Quirks
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Header;
