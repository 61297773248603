import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = ({ days, totalDays }) => {
  // Calculate the percentage
  const percentage = days < 0 ? 100 : Math.min((days / totalDays) * 100, 100);

  return (
    <div className="progress-circle-container">
      <div className="progress-circle" style={{ width: "200px", height: "200px" }}>
        <CircularProgressbar
          value={percentage}
          text={`${days} days`}
          styles={buildStyles({
            pathColor: "#0B6E4F",
            textColor: "#333",
            trailColor: "#2B4162",
            backgroundColor: "#f4f4f4",
            fontSize: "10px",
          })}
        />
      </div>
    </div>
  );
};

CircularProgress.propTypes = {
  days: PropTypes.number.isRequired,
  totalDays: PropTypes.number,
};

CircularProgress.defaultProps = {
  totalDays: 30,
};

export default CircularProgress;
