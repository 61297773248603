import { db } from "../../firebase.config";
import { useState, useEffect } from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import Header from "../Header/Header";

function Funcs() {

  return (
    <>
      <Header />
      <ModalComponent></ModalComponent>
    </>
  );
}

export default Funcs;
