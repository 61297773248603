import { db } from "../../firebase.config";
import { useState, useEffect } from "react";
import {
  collection, // Get reference to collection from firebase
  onSnapshot, // realtime functionality, runs everytime changes happen within the site
  doc,
  addDoc,
  deleteDoc 
} from "firebase/firestore";
import './Tracking.scss';
import Header from '../Header/Header';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import atelierCaveDark from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-cave-dark";
function Tracking(){
    const [tracking, setTracking] = useState ([]);
    const[form, setForm] = useState({
      client: "",
      assets: "",
      code: "",
      desc: "",
      docs: ""
    })
  
    const [popupActive, setPopupActive] = useState(false);
  
    const trackingCollectionRef = collection(db, "tracking");
    document.addEventListener("click", function (event) {
      let targetClass = event.target.classList;
      if (
        targetClass.contains("modalBackdrop") &&
        !targetClass.contains("hidden")
      ) {
        event.preventDefault();
        let btn = document.querySelector(".view.less");
        console.log(btn);
        btn.click();
      }
    });
    
  
    useEffect(() => {
      onSnapshot(trackingCollectionRef, snapshot => {
        setTracking(snapshot.docs.map(doc => {
          return{
            id: doc.id,
            viewing: false,
            ...doc.data()
          }
        }))
      })
    },[]) // empty array so only runs once on page load
  
  //Show more show less functionality
    const handleView = id => {
      const trackingClone = [...tracking];
  
      trackingClone.forEach(el => {
        if(el.id === id){
          el.viewing = !el.viewing;
          
        } else {
          el.viewing = false;
        }
      })
  
      setTracking(trackingClone)
    }
  
     //Function to add info straight to firebase and update app
    const handleSubmit = e => {
      e.preventDefault();
  
      if(!form.client || !form.assets || !form.code || !form.desc || !form.docs){
        alert("Please fill out all the fields");
        return
      }
  
      addDoc(trackingCollectionRef, form)
  
      setForm({
        client: "",
        assets: "",
        code: "",
        desc: "",
        docs: ""
      });
  
      setPopupActive(false);
    }
  
    const removeFunc = id => {
      let result = window.confirm("Are you sure you want to delete?")
      if(result){
        deleteDoc(doc(db, "tracking", id)) // delete functionality, deletes info from firebase and updates app in realtime
      }
    }

    function elementReady(selector) {
      return new Promise((resolve, reject) => {
          let el = document.querySelector(selector);
          if (el) {
            resolve(el);
            return
          }
          new MutationObserver((mutationRecords, observer) => {
            Array.from(document.querySelectorAll(selector)).forEach((element) => {
            resolve(element);
            observer.disconnect();
            });
          })
          .observe(document.documentElement, {
            childList: true,
            subtree: true
          });
      });
      }
      
      elementReady('.functions').then(() => {
        modalFunc();
        checkForModal();
        copyCode();
        filterClients();
        // redirectDocs();
      })

      function modalFunc(){
        let funcsContainer = document.querySelector('.functions')
        let copy = document.querySelectorAll('.copy');
        
        let observer = new MutationObserver(mutationRecords => {
          let viewButtons = document.querySelectorAll('.view');
            viewButtons.forEach(button => {
                  if(button.innerHTML === "View less"){
                    button.parentNode.parentNode.classList.add('viewing')
                  } else {
                    button.parentNode.parentNode.classList.remove('viewing')
                  }
            })
            copy.forEach(button => {
              if(button.parentNode.parentNode.classList.length === 2){
                button.classList.remove('hidden');
              } else {
                button.classList.add('hidden');
                button.nextElementSibling.classList.add('hidden')
              }
            })
        });
        
        // observe everything except attributes
        observer.observe(funcsContainer, {
          childList: true, // observe direct children
          subtree: true, // and lower descendants too
        });
      }

      function checkForModal(){
        let funcsContainer = document.querySelector('.functions')
        let modal = document.querySelector('.modalBackdrop');
        let observer = new MutationObserver(mutationRecords => {
            if(document.querySelector('.viewing')){
              modal.classList.remove('hidden');
            }else {
              modal.classList.add('hidden');
            }
        });
        
        // observe everything except attributes
        observer.observe(funcsContainer, {
          childList: true, // observe direct children
          subtree: true, // and lower descendants too
        });
      }

      function copyCode(){
        let copyButtons = document.querySelectorAll('.copy');
        copyButtons.forEach(button => {
          button.addEventListener('click', function(){
            let functionText = button.parentNode.previousSibling.firstChild.children[1].innerText;
            navigator.clipboard.writeText(functionText);
            button.innerText = 'Code copied'
          })
        })
        
      }

      function filterClients(){
        let clientSelect = document.getElementById('clients');
        clientSelect.onchange = (e) => {
            let clientOption = e.target.value;
            let clients = document.querySelectorAll('.function__name');
            clients.forEach(client => {
                console.log(client.innerHTML.toLowerCase())
                if(client.innerHTML.toLowerCase() !== clientOption){
                    client.parentNode.style.display = 'none';
                } else {
                    client.parentNode.style.display = 'block';
                }
            })
        }
      }

      // function redirectDocs(){
      //   let docs = document.querySelectorAll('.tracking');
      //   docs.forEach(doc => {
      //     doc.onclick = (e) => {
      //       e.preventDefault();
      //       window.location.href = doc.href
      //     }
      //   })
      // }
      

  
    return (
      <>
      <Header />
      <div className="App">
      <div className="hidden modalBackdrop"></div>
       <h1>Tracking</h1>
  
       <div className="filter-container">
            <button className = "slide_left" onClick={() => setPopupActive(!popupActive)}>Add Tracking</button>
            <select name="clients" id="clients">
                <option value="belmond">Belmond</option>
                <option value="monsoon">Monsoon</option>
                <option value="accessorise">Accessorise</option>
                <option value="ocado">Ocado</option>
                <option value="me+em">Me+em</option>
                <option value="finisterre">Finisterre</option>
                <option value="tfg">Tfg</option>
                <option value="laithwaites">Laithwaites</option>
                <option value="edyn">Edyn</option>
            </select>
        </div>
  
       <div className = "functions">
          {tracking.map((tracking, i) => (
            //Id to retrieve from firebase object
            <div className = "function" key = {tracking.id}>
  
              {/* get value from name in object */}
                <h3 className = "function__name">{ tracking.client }</h3>
                <div className="function__description-cont">
                  <h4>Description</h4>
                  <p className="function__description" dangerouslySetInnerHTML={{ __html: tracking.desc }}></p>
                </div>
              { tracking.viewing && <div className="function__info">
                {/* seperate div for function itself and description, for styling purposes */}
                <div className="function__container code">
      <h4>Code</h4>
      <p className="function__function">
        <SyntaxHighlighter language="javascript" style={atelierCaveDark}>
          {tracking.code}
        </SyntaxHighlighter>
      </p>
    </div>

                <div className = "function__container">
                  <h4>Assets</h4>
                  <p className = "function__function" dangerouslySetInnerHTML={{ __html: tracking.assets }}></p>
                </div>

                <div className = "function__container">
                  <h4>Docs</h4>
                  <a className = "function__function tracking" href = {tracking.docs} target="_blank" dangerouslySetInnerHTML={{ __html: tracking.docs }}></a>
                </div>
  
                
              </div>}
  
              <div className="buttons">
                <button className = "view less" onClick={() => handleView(tracking.id)}>View { tracking.viewing ? 'less' : 'more' }</button>
                <button className="remove" onClick={() => removeFunc(tracking.id)}>Remove</button>
                <button className="hidden copy">Copy Code</button>
                <p className="copied hidden">Code Copied!</p>
              </div>
            </div>
          ))}
       </div>
  
       {popupActive && <div className = "popup">
            <div className = "popup-inner">
              <h2>Add Tracking</h2>
  
              <form onSubmit={handleSubmit}>
  
                <div className="form-group">
                  <label>Client</label>
                  <input type="text" 
                  value = {form.client} 
                  onChange={e => setForm({...form, client: e.target.value})}/>
                </div>
  
                <div className="form-group">
                  <label>Description</label>
                  <textarea type="text" 
                  value = {form.desc} 
                  onChange={e => setForm({...form, desc: e.target.value})}/>
                </div>
  
                <div className="form-group">
                  <label>Code</label>
                  <textarea type="text" 
                  value = {form.code} 
                  onChange={e => setForm({...form, code: e.target.value})}/>
                </div>

                <div className="form-group">
                  <label>Assets</label>
                  <textarea type="text" 
                  value = {form.assets} 
                  onChange={e => setForm({...form, assets: e.target.value})}/>
                </div>

                <div className="form-group">
                  <label>Docs</label>
                  <textarea type="text" 
                  value = {form.docs} 
                  onChange={e => setForm({...form, docs: e.target.value})}/>
                </div>
  
                <div className="buttons">
                  <button typeof="submit">Submit</button>
                  <button type="button" className="remove" onClick={() => setPopupActive(false)}>
                    Close
                  </button>
                </div>
  
              </form>
  
            </div>
       </div>
       } 
      </div>
      </>
    );
}

export default Tracking;


//retry deploy




