import React from "react";
import { bool, func } from "prop-types";
import { StyledBurger } from "./Hamburger.styled";
import { Link } from "react-router-dom";
import logo from '../../abstracts/conversio_logo.svg';

const Hamburger = ({ open, setOpen, ...props }) => {
  const isExpanded = open ? true : false;

  return (
    <>
    <Link to="/homepage">
    <img className="header__logo" src="https://conversio.com/wp-content/uploads/2021/11/conversio_logo.svg" alt="Conversio logo" />
  </Link>
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span  className="hamburger-span"/>
      <span  className="hamburger-span"/>
      <span  className="hamburger-span"/>
    </StyledBurger>
    </>
  );
};

Hamburger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Hamburger;
