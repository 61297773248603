import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import styled from "styled-components";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels); // Register the plugin

const ChartContainer = styled.div`
  width: 100%;
  height: 400px; /* Default height */

  @media (max-width: 768px) {
    height: 300px; /* Adjust height for mobile */
  }

  @media (max-width: 480px) {
    height: 250px; /* Further adjustment for smaller mobile screens */
  }
`;

function ComparisonGraph({ devsDaysAvailable, devsWorkingDays, consultantsDaysAvailable, consultantsWorkingDays }) {
  // Calculate percentages
  const devsAvailablePercentage = (devsWorkingDays / devsDaysAvailable) * 100;
  const consultantsAvailablePercentage = (consultantsWorkingDays / consultantsDaysAvailable) * 100;

  const data = {
    labels: ["Developers", "Consultants"],
    datasets: [
      {
        label: "Total Working Days (100%)",
        data: [100, 100], // Total Working Days set to 100% for both
        backgroundColor: "#FA9F60",
        borderColor: "#FA9F60",
        borderWidth: 1,
        barPercentage: 0.4,
        categoryPercentage: 0.4,
      },
      {
        label: "Available Resource (%)",
        data: [devsAvailablePercentage, consultantsAvailablePercentage],
        backgroundColor: "#A63446",
        borderColor: "#A63446",
        borderWidth: 1,
        barPercentage: 0.4,
        categoryPercentage: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Comparison of Developer and Consultant Available Resource",
      },
      datalabels: {
        color: "black",
        display: true,
        anchor: "end",
        align: "top",
        formatter: (value) => `${value.toFixed(1)}%`, // Format data labels as percentages
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        max: 120, // Set the max value to 120% for extra space
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + "%"; // Append percentage sign
          },
        },
      },
    },
  };

  return (
    <ChartContainer>
      <Bar data={data} options={options} />
    </ChartContainer>
  );
}

export default ComparisonGraph;
