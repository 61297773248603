import React, { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import { collection, onSnapshot, addDoc, deleteDoc, doc } from "firebase/firestore";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { atelierForestDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Header from "../Header/Header";
import "./ModalComponent.scss";

// Function component for individual function item
function FunctionItem({ func, onView, onRemove, onCopy }) {
  const [copied, setCopied] = useState(false);

  const handleCopyCode = () => {
    onCopy(func.func);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="function-elements">
      <h3 className="function__name">{func.name}</h3>
      <div className="function__description-cont">
        <h4>Description</h4>
        <p className="function__description" dangerouslySetInnerHTML={{ __html: func.desc }}></p>
      </div>
      {func.viewing && (
        <div className="function__info">
          <div className="function__container function-code">
            <h4>Code</h4>
            <p className="function__function">
              <SyntaxHighlighter language="javascript" style={atelierForestDark}>
                {func.func}
              </SyntaxHighlighter>
            </p>
          </div>
        </div>
      )}
      <div className="buttons">
        <button className="view less" onClick={() => onView(func.id)}>
          View {func.viewing ? "less" : "more"}
        </button>
        <button className="remove" onClick={() => onRemove(func.id)}>
          Remove
        </button>
        {func.viewing && (
          <button className="copy" onClick={handleCopyCode}>
            Copy Code
          </button>
        )}
        {copied && <p className="copied">Code Copied!</p>}
      </div>
    </div>
  );
}

function ModalComponent() {
  const [funcs, setFuncs] = useState([]);
  const [popupActive, setPopupActive] = useState(false);
  const [form, setForm] = useState({ name: "", func: "", desc: "" });

  const funcsCollectionRef = collection(db, "functions");

  useEffect(() => {
    const unsubscribe = onSnapshot(funcsCollectionRef, (snapshot) => {
      setFuncs(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          viewing: false,
          ...doc.data(),
        }))
      );
    });
    return () => unsubscribe();
  }, []);

  const handleView = (id) => {
    setFuncs(funcs.map((func) => ({ ...func, viewing: func.id === id ? !func.viewing : false })));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.name || !form.func || !form.desc) {
      alert("Please fill out all the fields");
      return;
    }
    addDoc(funcsCollectionRef, form);
    setForm({ name: "", func: "", desc: "" });
    setPopupActive(false);
  };

  const removeFunc = (id) => {
    let result = window.confirm("Are you sure you want to delete?");
    if (result) {
      deleteDoc(doc(db, "functions", id));
    }
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  return (
    <div className="App">
      <div className="hidden modalBackdrop"></div>
      <h1>Functions</h1>
      <button className="slide_left" onClick={() => setPopupActive(!popupActive)}>
        Add function
      </button>
      <div className="functions-wrapper">
        {funcs.map((func, i) => (
          <FunctionItem
            key={func.id}
            func={func}
            onView={handleView}
            onRemove={removeFunc}
            onCopy={handleCopyCode}
          />
        ))}
      </div>
      {popupActive && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Add a function</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input type="text" value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
              </div>
              <div className="form-group">
                <label>Function</label>
                <textarea
                  type="text"
                  value={form.func}
                  onChange={(e) => setForm({ ...form, func: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  type="text"
                  value={form.desc}
                  onChange={(e) => setForm({ ...form, desc: e.target.value })}
                />
              </div>
              <div className="buttons">
                <button typeof="submit">Submit</button>
                <button type="button" className="remove" onClick={() => setPopupActive(false)}>
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalComponent;
