import "./Homepage.scss";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../abstracts/conversio_logo.svg";
import Header from "../Header/Header";
// import { useState } from 'react'
// import {useNavigate} from 'react-router-dom';

// Main

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showElement: false,
    };
  }

  componentDidMount() {
    this.setState({ showElement: true });

    this.timeout = setTimeout(() => {
      this.setState({ showElement: false });
    }, 5000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { showElement } = this.state;
    const firstName = localStorage.getItem("firstName");
    return (
      <>
        <Header></Header>
        <div className="homepage">
          <div className={`welcome ${showElement ? "fade-in" : "fade-out"}`}>
            {firstName && <p>Hello {firstName}, welcome :D</p>}
          </div>
          {/* <img className='homepage__logo' src={logo} alt="Conversio logo" /> */}
          <div className="homepage__buttons-container">
            <Link className="homepage__nav" to="/functions">
              <button className="homepage__button slide_left">Functions</button>
            </Link>
            <Link className="homepage__nav" to="/walkthroughdevs">
              <button className="homepage__button slide_left">
                Walkthroughs Dev
              </button>
            </Link>
            <Link className="homepage__nav" to="/walkthroughcros">
              <button className="homepage__button slide_left">
                Walkthroughs CRO
              </button>
            </Link>
            {/* <Link className='homepage__nav' to='/uat'><button className = "homepage__button slide_left">UAT</button></Link> */}
            <Link className="homepage__nav" to="/quirks">
              <button className="homepage__button slide_left">Quirks</button>
            </Link>
            <Link className="homepage__nav" to="/tracking">
              <button className="homepage__button slide_left">Tracking</button>
            </Link>
            <Link className="homepage__nav" to="/TestTable">
              <button className="homepage__button slide_left">Holidays</button>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Homepage;
