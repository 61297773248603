
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider, getAuth } from "firebase/auth";

const firebaseConfig = {
apiKey: "AIzaSyAUOJTnw3GSZbTg0SbDPGsJ8AS0i5e09Ro",
  authDomain: "conversio-dbe4c.firebaseapp.com",
  databaseURL: "https://conversio-dbe4c-default-rtdb.firebaseio.com",
  projectId: "conversio-dbe4c",
  storageBucket: "conversio-dbe4c.appspot.com",
  messagingSenderId: "141416485309",
  appId: "1:141416485309:web:07767e563f06db270642d4"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
