import { db } from "../../firebase.config";
import { useState, useEffect } from "react";
import {
  collection, // Get reference to collection from firebase
  onSnapshot, // realtime functionality, runs everytime changes happen within the site
  doc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import "./Quirks.scss";
import Header from "../Header/Header";
import { useRef } from "react";

function Quirks() {
  const [quirks, setQuirks] = useState([]);
  const [form, setForm] = useState({
    client: "",
    quirk: "",
    desc: "",
  });
  document.addEventListener("click", function (event) {
    let targetClass = event.target.classList;
    if (
      targetClass.contains("modalBackdrop") &&
      !targetClass.contains("hidden")
    ) {
      event.preventDefault();
      let btn = document.querySelector(".view.less");
      console.log(btn);
      btn.click();
    }
  });

  const [popupActive, setPopupActive] = useState(false);

  const quirksCollectionRef = collection(db, "quirks");

  useEffect(() => {
    onSnapshot(quirksCollectionRef, (snapshot) => {
      setQuirks(
        snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            viewing: false,
            ...doc.data(),
          };
        })
      );
    });
  }, []);
  //Show more show less functionality
  const handleView = (id) => {
    const quirksClone = [...quirks];

    quirksClone.forEach((func) => {
      if (func.id === id) {
        func.viewing = !func.viewing;
      } else {
        func.viewing = false;
      }
    });

    setQuirks(quirksClone);
  };

  //Function to add functions straight to firebase and update app
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.client || !form.quirk || !form.desc) {
      alert("Please fill out all the fields");
      return;
    }

    addDoc(quirksCollectionRef, form);

    setForm({
      client: "",
      quirk: "",
      desc: "",
    });

    setPopupActive(false);
  };

  const removeFunc = (id) => {
    let result = window.confirm("Are you sure you want to delete?");
    if (result) {
      deleteDoc(doc(db, "quirks", id)); // delete functionality, deletes function from firebase and updates app in realtime
    }
  };

  function elementReady(selector) {
    return new Promise((resolve, reject) => {
      let el = document.querySelector(selector);
      if (el) {
        resolve(el);
        return;
      }
      new MutationObserver((mutationRecords, observer) => {
        Array.from(document.querySelectorAll(selector)).forEach((element) => {
          resolve(element);
          observer.disconnect();
        });
      }).observe(document.documentElement, {
        childList: true,
        subtree: true,
      });
    });
  }

  elementReady(".functions").then(() => {
    modalFunc();
    checkForModal();
    filterClients();
  });

  function modalFunc() {
    let funcsContainer = document.querySelector(".functions");

    let observer = new MutationObserver((mutationRecords) => {
      let viewButtons = document.querySelectorAll(".view");
      viewButtons.forEach((button) => {
        if (button.innerHTML === "View less") {
          button.parentNode.parentNode.classList.add("viewing");
        } else {
          button.parentNode.parentNode.classList.remove("viewing");
        }
      });
    });

    // observe everything except attributes
    observer.observe(funcsContainer, {
      childList: true, // observe direct children
      subtree: true, // and lower descendants too
    });
  }

  function checkForModal() {
    let funcsContainer = document.querySelector(".functions");
    let modal = document.querySelector(".modalBackdrop");
    let observer = new MutationObserver((mutationRecords) => {
      if (document.querySelector(".viewing")) {
        modal.classList.remove("hidden");
      } else {
        modal.classList.add("hidden");
      }
    });

    // observe everything except attributes
    observer.observe(funcsContainer, {
      childList: true, // observe direct children
      subtree: true, // and lower descendants too
    });
  }

  function filterClients() {
    let clientSelect = document.getElementById("clients");
    clientSelect.onchange = (e) => {
      let clientOption = e.target.value;
      let clients = document.querySelectorAll(".function__name");
      clients.forEach((client) => {
        console.log(client.innerHTML.toLowerCase());
        if (client.innerHTML.toLowerCase() !== clientOption) {
          client.parentNode.style.display = "none";
        } else {
          client.parentNode.style.display = "block";
        }
      });
    };
  }

  return (
    <>
      <Header />
      <div className="App">
        <div className="hidden modalBackdrop"></div>
        <h1>Quirks</h1>

        <div className="filter-container">
          <button
            className="slide_left"
            onClick={() => setPopupActive(!popupActive)}
          >
            Add Quirk
          </button>
          <select name="clients" id="clients">
            <option value="belmond">Belmond</option>
            <option value="monsoon">Monsoon</option>
            <option value="accessorise">Accessorise</option>
            <option value="ocado">Ocado</option>
            <option value="me+em">Me+em</option>
            <option value="finisterre">Finisterre</option>
            <option value="tfg">TFG</option>
            <option value="laithwaites">Laithwaites</option>
            <option value="edyn">Edyn</option>
          </select>
        </div>

        <div className="functions">
          {quirks.map((quirk, i) => (
            //Id to retrieve from firebase object
            <div className="function" key={quirk.id}>
              {/* get value from name in object */}
              <h3 className="function__name">{quirk.client}</h3>
              <div className="function__container">
                <h4>Quirk</h4>
                <p
                  className="function__function"
                  dangerouslySetInnerHTML={{ __html: quirk.quirk }}
                ></p>
              </div>

              {quirk.viewing && (
                <div className="function__info">
                  {/* seperate div for function itself and description, for styling purposes */}
                  <div className="function__description-cont">
                    <h4>Description</h4>
                    <p
                      className="function__description"
                      dangerouslySetInnerHTML={{ __html: quirk.desc }}
                    ></p>
                  </div>
                </div>
              )}

              <div className="buttons">
                <button
                  className="view less"
                  onClick={() => handleView(quirk.id)}
                >
                  View {quirk.viewing ? "less" : "more"}
                </button>
                <button className="remove" onClick={() => removeFunc(quirk.id)}>
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>

        {popupActive && (
          <div className="popup">
            <div className="popup-inner">
              <h2>Add a Quirk</h2>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Client</label>
                  <input
                    type="text"
                    value={form.client}
                    onChange={(e) =>
                      setForm({ ...form, client: e.target.value })
                    }
                  />
                </div>

                <div className="form-group">
                  <label>Quirk</label>
                  <textarea
                    type="text"
                    value={form.quirk}
                    onChange={(e) =>
                      setForm({ ...form, quirk: e.target.value })
                    }
                  />
                </div>

                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    type="text"
                    value={form.desc}
                    onChange={(e) => setForm({ ...form, desc: e.target.value })}
                  />
                </div>

                <div className="buttons">
                  <button typeof="submit">Submit</button>
                  <button
                    type="button"
                    className="remove"
                    onClick={() => setPopupActive(false)}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Quirks;
